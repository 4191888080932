label:not(.custom-control-label){
	font-size: $font-size-sm;

	&.required::after{
		color: theme-color('danger');
		content: ' *';
		font-size: $small-font-size;
		vertical-align: super;
	}
}

.form-group{
	&.is-invalid{
		.input-group{
			&.focus .input-group-text,
			.input-group-text{
				border-color: $form-feedback-invalid-color;
			}

			.btn{
				border-color: $form-feedback-invalid-color;
			}
		}

		.form-control{
			border-color: $form-feedback-invalid-color;
		}

		.custom-control-label::before{
			border-color: $form-feedback-invalid-color;
		}
	}
	
	&.is-valid{
		.input-group{
			&.focus .input-group-text,
			.input-group-text{
				border-color: $form-feedback-valid-color;
			}

			.btn{
				border-color: $form-feedback-valid-color;
			}
		}

		.form-control{
			border-color: $form-feedback-valid-color;
		}
	}
}

.form-control{
	&:-webkit-autofill{
		box-shadow: 0 0 0 2rem white inset !important;
		-webkit-box-shadow: 0 0 0 2rem white inset !important;
	}

	&[type="number"]{
		-moz-appearance: textfield;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			margin: 0;
			-webkit-appearance: none;
		}
	}
}

.invalid-feedback{
	&.filled{
		display: block;
	}
}

.form-action{
	align-items: center;
	display: flex;
	padding-top: $spacer / 2;
}

.form-title{
	margin-bottom: 1.25rem;

	&:not(:first-of-type){
		margin-top: .5rem;
	}
}

.form-upload{
	display: flex;
	flex-direction: column;
}