// Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap');
@import url('/fonts/font-awesome/css/all.min.css');

// Variables
@import '~bootstrap/scss/functions';
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Components
@import 'buttons';
@import 'card';
@import 'daterangepicker';
@import 'form';
@import 'input-group';
@import 'modals';
@import 'navbar';
@import 'perfect-scroll';
@import 'preloader';
@import 'reboot';
@import 'select2';
@import 'sidebar';
@import 'sweetalert';
@import 'tables';
@import 'type';