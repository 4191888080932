.swal-title{
	&:first-child{
		margin-top: 1rem;
	}

	&:not(:last-child){
		margin-bottom: 1rem;
	}
}

.swal-button{
	&:not([disabled]){
		&.btn-danger{
			&:hover{
				background-color: darken(theme-color('danger'), 7.5%);
			}
		}

		&.btn-primary{
			&:hover{
				background-color: darken(theme-color('primary'), 7.5%);
			}
		}

		&.btn-flat{
			&:hover{
				background-color: transparent;
			}
		}
	}
}