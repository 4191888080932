.table{
	font-size: $font-size-sm;
	width: 100% !important;
}

.table-fit{
	width: 1px;
}

table{
	&.dataTable{
		&.dtr-inline{
			&.collapsed{
				&> tbody{
					&> tr[role=row]{
						&> td{
							&.dtr-control{
								&::before{
									margin-top: .75rem;
									top: 0;
								}
							}
						}
						&> th{
							&.dtr-control{
								&::before{
									margin-top: .75rem;
									top: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}

.dataTables_wrapper{
	.dataTables_processing{
		align-items: center;
		background-color: rgba($white, .75);
		bottom: 0;
		display: flex;
		justify-content: center;
		left: 0 !important;
		margin-left: 0 !important;
		margin-top: 0 !important;
		padding: 0 !important;
		right: 0;
		top: 0 !important;
		width: auto !important;
	}
}

td{
	li{
		&.text-center{
			text-align: left !important;
		}
	}
}

.paginate_button{
	border-radius: $btn-border-radius;
	color: theme-color('primary');
	cursor: pointer;

	&.current{
		background-color: theme-color('primary');
		box-shadow: $btn-box-shadow;
		color: $white;

		&:hover{
			color: $white;
		}
	}
}