.sidebar{
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: fixed;
	top: 0;
	transform: translateX(-100%);
	transition: $transition-base;
	width: $sidebar-width;
	z-index: 1030;

	@include media-breakpoint-up(lg){
		box-shadow: $box-shadow-sm;
		transform: none;
	}

	&.toggled{
		box-shadow: $box-shadow-sm;
		transform: none;
	}

	@include media-breakpoint-up(lg){
		&.toggled{
			width: $sidebar-width-sm;

			.sidebar-brand .brand-text,
			.sidebar-nav .nav-link .link-text{
				opacity: 0;
			}

			.sidebar-nav .nav-title{
				background-color: $text-muted;
				color: transparent;
			}

			&.hover{
				width: $sidebar-width;

				.sidebar-brand .brand-text,
				.sidebar-nav .nav-link .link-text{
					opacity: 1;
				}

				.sidebar-nav .nav-title{
					background-color: transparent;
					color: $text-muted;
				}
			}
		}
	}
}

.sidebar-header{
	align-items: center;
	display: flex;
	height: $navbar-height;
	justify-content: space-between;
	padding: $sidebar-padding-y ($spacer / 2);
	position: relative;

	&::after{
		background-image: linear-gradient(to bottom, $white 0%, transparent 100%);
		bottom: 0;
		content: '';
		height: .5rem;
		left: 0;
		position: absolute;
		transform: translateY(100%);
		width: 100%;
		z-index: 1;
	}
}

.sidebar-brand{
	align-items: center;
	color: $link-color;
	display: inline-flex;
	flex: 1 1 auto;
	padding-bottom: $spacer / 2;
	padding-top: $spacer / 2;

	&:hover{
		color: $link-color;
		text-decoration: none;
	}

	.brand-img{
		width: $sidebar-width-sm - $spacer;
	}

	.brand-text{
		font-size: $font-size-lg;
		margin-bottom: 0;
		padding-left: $spacer / 2;
		padding-right: $spacer / 2;
	}
}

.sidebar-toggle{
	cursor: pointer;
	display: inherit;
	font-size: $font-size-lg;

	&::before{
		content: '\f0c9';
		font-family: $font-family-icon;
		font-weight: 300;
		line-height: 1;

		@include media-breakpoint-up(lg){
			content: '\f205';
		}
	}

	&.toggled{
		&::before{
			content: '\f00d';
			margin-right: $spacer / 2;

			@include media-breakpoint-up(lg){
				content: '\f204';
				margin-right: 0;
			}
		}
	}
}

.sidebar-nav{
	display: flex;
	flex-direction: column;
	height: calc(100vh - #{$navbar-height});
	list-style: none;
	margin-bottom: 0;
	overflow: hidden;
	padding: $sidebar-padding-x / 2;
	position: relative;

	.nav-link{
		border-radius: $border-radius-sm;
		color: $body-color;
		display: flex;
		margin-bottom: $spacer / 2;
		padding: 0;

		&:not(.active):hover{
			.link-icon{
				color: $link-color;
			}
		}

		&.active{
			background-color: theme-color('primary');
			box-shadow: $btn-box-shadow;
			color: $yiq-text-light;
		}

		&[data-toggle="collapse"]{
			position: relative;

			&::after{
				content: '\f107';
				font-family: $font-family-icon;
				font-size: $font-size-sm;
				font-weight: 300;
				line-height: 1.75;
				padding: $nav-link-padding-y $nav-link-padding-x;
				position: absolute;
				right: 0;
				top: 0;
				transition: $transition-base;
			}

			&[aria-expanded="true"]{
				&::after{
					transform: rotate(-180deg);
				}
			}
		}

		.link-icon{
			display: flex;
			font-size: $font-size-lg;
			justify-content: center;
			line-height: $headings-line-height;
			min-width: 1.5rem + $nav-link-padding-x * 2;
			padding: $nav-link-padding-y $nav-link-padding-x;
			transition: $transition-base;
		}

		.link-text{
			padding: $nav-link-padding-y $nav-link-padding-x $nav-link-padding-y .25rem;
			position: relative;
			transition: $transition-base;
			white-space: nowrap;
		}
	}

	.nav-title{
		color: $text-muted;
		font-size: $small-font-size;
		font-weight: $font-weight-bold;
		margin-bottom: $spacer / 2;
		margin-top: $spacer / 2;
		text-transform: uppercase;
	}
}

.sidebar-subnav{
	display: flex;
	flex-direction: column;
	list-style: none;
	margin-bottom: 0;
	padding-left: 0;

	.nav-item{
		&:last-of-type{
			.nav-link{
				margin-bottom: $spacer / 2;
			}
		}
	}

	.nav-link{
		font-size: $font-size-sm;
		margin-bottom: 0;
		padding-left: 1.5rem + $nav-link-padding-x * 2;
		position: relative;

		&::before{
			background-color: $gray-300;
			bottom: 0;
			content: '';
			left: calc(.75rem + #{$nav-link-padding-x} - 1px);
			position: absolute;
			top: 0;
			transition: $transition-base;
			width: .125rem;
		}

		&:not(.active):hover{
			&::before{
				background-color: $link-color;
			}
		}

		&.active{
			background-color: transparent;
			color: theme-color('primary');
			box-shadow: none;

			&::before{
				background-color: $link-color;
			}
		}
	}
}

.sidebar-backdrop{
	background-color: rgba($black, .5);
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1029;

	@include media-breakpoint-up(lg){
		display: none;
	}
}
