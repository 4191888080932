.input-group{
	&.focus{
		.input-group-text{
			border-color: $input-focus-border-color;
			color: theme-color('primary');
		}

		.btn{
			border-color: $input-focus-border-color;
		}
	}

	.form-control{
		border-left-width: 0;
		border-right-width: 0;
	}

	.btn{
		border-color: $input-border-color;
		z-index: 4;
	}
}

.ig-prepend{
	.form-control{
		border-right-width: $input-border-width;
	}
}

.ig-append{
	.form-control{
		border-left-width: $input-border-width;
	}
}

.input-group-text{
	position: relative;

	&.readonly{
		background-color: $input-disabled-bg;
	}
}

.input-group-prepend{
	.input-group-text{
		border-right-color: transparent !important;

		&::after{
			background-color: $input-border-color;
			bottom: $input-padding-y;
			content: '';
			position: absolute;
			right: 0;
			top: $input-padding-y;
			width: $border-width;
		}
	}
}

.input-group-append{
	.input-group-text{
		border-left-color: transparent !important;

		&::before{
			background-color: $input-border-color;
			bottom: $input-padding-y;
			content: '';
			left: 0;
			position: absolute;
			top: $input-padding-y;
			width: $border-width;
		}
	}
}