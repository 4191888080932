.select2-container--bootstrap4{
	width: 100% !important;

	&.select2-container--below{
		.select2-selection[aria-expanded="true"]{
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			border-top-width: $input-border-width;
		}
	}

	&.select2-container--above{
		.select2-selection[aria-expanded="true"]{
			border-bottom-width: $input-border-width;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}

	&.select2-container--focus{
		.select2-selection{
			box-shadow: none;
		}
	}

	&.select2-container--open{
		.select2-selection{
			border-color: $input-focus-border-color;
		}
	}

	&.select2-container--disabled{
		&.select2-container--focus{
			.select2-selection{
				background-color: $input-disabled-bg;
				border-color: $input-border-color;
				cursor: auto;
			}

			.select2-selection__arrow b{
				border-color: $gray-800 transparent transparent;
			}
		}

		.select2-selection{
			background-color: $input-disabled-bg;
			border-color: $input-border-color;
			cursor: auto;
		}
	}

	.select2-selection{
		border: $input-border-width solid $input-border-color;
		border-radius: $input-border-radius;

		&[aria-expanded=true]{
			border-color: $input-focus-border-color;
		}
	}

	.select2-selection--single{
		.select2-selection__placeholder{
			color: $input-placeholder-color;
		}

		.select2-selection__rendered{
			color: $input-color;
		}
	}

	.select2-dropdown{
		border-color: $input-focus-border-color;
	}

	.select2-search--dropdown{
		.select2-search__field{
			border-radius: $input-border-radius;

			&:focus{
				border-color: $input-focus-border-color;
			}
		}
	}

	.select2-results__message{
		color: $gray-500;
		font-size: $font-size-sm;
	}

	.select2-results__option--highlighted{
		background-color: theme-color('primary');

		&.select2-results__option[aria-selected=true]{
			background-color: theme-color('primary');
		}
    }

    .select2-selection__clear{
        margin-left: .3em;
        padding-top: .1em;
    }
}

.form-group{
	&.is-valid{
		.select2-container--bootstrap4{
			.select2-selection{
				border-color: theme-color('success');
			}
		}
	}

	&.is-invalid{
		.select2-container--bootstrap4{
			.select2-selection{
				border-color: theme-color('danger');
			}
		}
	}
}

.select2-filter-container{
    @include media-breakpoint-up(lg){
        min-width: 18rem;
    }
}
