.preloader{
  align-items: center;
  background-color: $white;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;

  .loader{
    animation: loader 2s infinite ease;
    border: .25rem solid theme-color('primary');
    display: block;
    height: 2.5rem;
    width: 2.5rem;
  }
  
  .loader-inner{
    animation: loader-inner 2s infinite ease-in;
    background-color: theme-color('primary');
    display: inline-block;
    vertical-align: top;
    width: 100%;
  }
}

@keyframes loader{
  0%{
    transform: rotate(0deg);
  }
  
  25%{
    transform: rotate(180deg);
  }
  
  50%{
    transform: rotate(180deg);
  }
  
  75%{
    transform: rotate(360deg);
  }
  
  100%{
    transform: rotate(360deg);
  }
}

@keyframes loader-inner{
  0%{
    height: 100%;
  }
  
  25%{
    height: 100%;
  }
  
  50%{
    height: 0%;
  }
  
  75%{
    height: 0%;
  }
  
  100%{
    height: 100%;
  }
}