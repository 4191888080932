.btn:not(.btn-link){
	font-weight: 500;
}

.btn-link,
.btn-flat{
	&:active{
		box-shadow: none !important;
	}
}

.btn:focus{
	box-shadow: none !important;
}