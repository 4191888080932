.navbar{
	height: $navbar-height;
	padding-bottom: 0;
	padding-top: 0;
	margin-bottom: $spacer;

	&.sticked{
		border-bottom: $border-width solid rgba($black, .125);
		
		.navbar-brand{
			opacity: 1;
			visibility: visible;
		}
	}

	.btn{
		min-height: 2.5rem;
	}

	.btn-avatar{
		border-color: $white;
		border-width: .125rem;
		border-radius: 50%;
		padding: 0;

		img{
			border-radius: 50%;
			object-fit: cover;
			height: 1.25rem + $btn-padding-y * 2;
			width: 1.25rem + $btn-padding-y * 2;
		}
	}
}

.navbar-brand{
	opacity: 0;
	transition: $transition-base;
	visibility: hidden;
}