html{
  height: 100vh;
	overflow: hidden;
	
	&.loaded{
		height: inherit;
		overflow: inherit;
	}
}

body{
	overflow-x: hidden;
	
	&.toggled{
		overflow: hidden;

		@include media-breakpoint-up(lg){
			overflow: auto;
		}

		.app-content{
			@include media-breakpoint-up(lg){
				margin-left: $sidebar-width-sm;
			}
		}
	}
}

.auth{
	background: url('/img/bg-auth.jpg') center/cover no-repeat;
	min-height: 100vh;
}

.app-content{
	min-height: 100vh;
	padding-bottom: 4rem;
	position: relative;

	@include media-breakpoint-up(lg){
		margin-left: $sidebar-width;
	}
}

header{
	padding-bottom: 2rem;
	padding-top: calc(#{$navbar-height} + 2rem);
	text-align: center;
}

footer{
	background-color: $white;
	bottom: 0;
	border-top: $border-width solid rgba($black, .125);
	font-size: $small-font-size;
	left: 0;
	padding-bottom: $spacer;
	padding-top: $spacer;
	position: absolute;
	right: 0;
}

dt{
	font-weight: 600;
}