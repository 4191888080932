.ps__thumb-y{
  background-color: rgba($black, .5);
  margin-bottom: .125rem;
  margin-top: .125rem;
  right: .125rem;
  width: .25rem;
}

.ps__rail-y{
  border-radius: .25rem;
  margin-bottom: .375rem;
  margin-top: .375rem;
  right: 0;
  width: .5rem;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y{
  background-color: rgba($black, .625);
  width: .25rem;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking{
  background-color: transparent;
}