.card{
	box-shadow: $box-shadow;
	overflow: hidden;

	& > .row{
		.card-img{
			@include media-breakpoint-up(md){
				border-radius: $card-inner-border-radius 0 0 $card-inner-border-radius;
				max-width: 360px;
			}
			
			border-radius: $card-inner-border-radius $card-inner-border-radius 0 0;
			height: 100%;
			max-width: 100%;
			object-fit: cover;
		}
	}
}

.card-footer:last-child{
	border-top: 0;
	position: relative;

	&::before{
		background-color: $card-border-color;
		content: '';
		height: $border-width;
		left: $spacer;
		position: absolute;
		right: $spacer;
		top: 0;
	}
}

.card-title{
	font-weight: $font-weight-bold;
}

.card-icon{
	bottom: 0;
	color: $white;
	opacity: .375;
	position: absolute;
    right: 0;
    transform: translate(10%, 10%);
}